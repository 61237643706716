import {createStore} from 'redux';

const initialstate = {
    id: 1,
    inforContrato: [],
    infoContratTrabajador: [],
    InfoEmpresa: [],
    tdDataInfo: [],
    point: '',
    userConectado: [],
    agregarPersona: false,
};

const reducer = (state = initialstate, action) => {
    if (action.type === "AGREGAR_PERSONA") {
        return {
            ...state,
            agregarPersona: action.agregarPersona
        };

    }
    if (action.type === "MODIFICAR_ID") {
        return {
            ...state,
            id: action.id
        };
    }
    if (action.type === "INFO_CONTRATO") {
        return {
            ...state,
            inforContrato: action.inforContrato,
        };
    }
    if (action.type === "INFO_CONTRATO_TRABAJADOR") {
        return {
            ...state,
            infoContratTrabajador: action.infoContratTrabajador
        };
    }
    if (action.type === "INFO_EMPRESA") {
        return {
            ...state,
            InfoEmpresa: action.InfoEmpresa
        };
    }
    if (action.type === "AGREGAR_TD") {
        return {
            ...state,
            tdDataInfo: state.tdDataInfo.concat(action.tdDataInfo),
        };
    }
    if (action.type === "POINT") {
        return {
            ...state,
            point: action.point
        };
    }
    if (action.type === "USUARIO") {
        return {
            ...state,
            userConectado: action.userConectado
        };
    }

    console.log(action);
    return state;

};


export default createStore(reducer);
